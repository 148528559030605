import axios from 'axios';
import Vue from 'vue'

const BASE_URL = 'https://licenseinventory.com.br/'; //production
// const BASE_URL = 'https://localhost:5001/'; //develop 

/**
 * Retrieves the authorization header with the bearer token from the Vue instance.
 * @returns {Object} - An object containing the authorization header.
 */
const getAuthHeader = async () => {
    return {
        Authorization: 'Bearer ' + Vue.$keycloak.token,
    }
};

/**
 * Performs a GET request to the specified URL with optional headers and includes the authorization header.
 * @param {string} url - The URL to send the GET request to.
 * @param {Object} header - Optional headers to include in the request.
 * @returns {Promise} - A promise that resolves with the response data from the GET request.
 */
export const get = async (url, header) => {
    var authHeader = await getAuthHeader();

    return await axios.get(BASE_URL + url, { headers: { ...authHeader, ...header } });
};

/**
 * Performs a POST request to the specified URL with optional headers, request data, and includes the authorization header.
 * @param {string} url - The URL to send the POST request to.
 * @param {Object} header - Optional headers to include in the request.
 * @param {Object} requestData - The data to be sent in the POST request body.
 * @returns {Promise} - A promise that resolves with the response data from the POST request.
 * @throws {Error} - Throws an error if the request fails.
 */
export const post = async (url, header, requestData) => {
    var authHeader = await getAuthHeader();

    return await axios.post(BASE_URL + url, requestData, { headers: { ...authHeader, ...header } }).catch(error => {
        throw error;
    });
};

/**
 * Performs a PUT request to the specified URL with optional headers, request data, and includes the authorization header.
 * @param {string} url - The URL to send the PUT request to.
 * @param {Object} header - Optional headers to include in the request.
 * @param {Object} requestData - The data to be sent in the PUT request body.
 * @returns {Promise} - A promise that resolves with the response data from the PUT request.
 * @throws {Error} - Throws an error if the request fails.
 */
export const put = async (url, header, requestData) => {
    var authHeader = await getAuthHeader();

    return await axios.put(BASE_URL + url, requestData, { headers: { ...authHeader, ...header } }).catch(error => {
        throw error;
    });
};

/**
 * Performs a DELETE request to the specified URL with optional headers and includes the authorization header.
 * @param {string} url - The URL to send the DELETE request to.
 * @param {Object} header - Optional headers to include in the request.
 * @returns {Promise} - A promise that resolves with the response data from the DELETE request.
 * @throws {Error} - Throws an error if the request fails.
 */
export const del = async (url, header) => {
    var authHeader = await getAuthHeader();

    return await axios.delete(BASE_URL + url, { headers: { ...authHeader, ...header } }).catch(error => {
        throw error;
    });
};

