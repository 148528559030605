import {
    post,
    get,
    del
} from '../apiRequest'

/**
 * Retrieves all companies by sending a GET request to the specified API endpoint.
 * @returns {Promise} - A promise that resolves with the API response data.
 * @throws {Error} - Throws an error if the request fails.
 */
export const getCompanies = async () => {
    const apiResponse = await get('api/company', {}).then(res => {
        return res;
    }).catch(error => {
        throw error;
    });

    return apiResponse;
}

/**
 * Retrieves information for a specific company by sending a GET request to the specified API endpoint.
 * @param {number} companyId - The ID of the company to retrieve information for.
 * @returns {Promise} - A promise that resolves with the API response data.
 * @throws {Error} - Throws an error if the request fails.
 */
export const getCompanyInfo = async (companyId) => {
    const apiResponse = await get('api/company/' + companyId, {}).then(res => {
        return res;
    }).catch(error => {
        throw error;
    });

    return apiResponse;
}

/**
 * Saves a company by sending a POST request to the specified API endpoint.
 * @param {Object} company - The company object to be saved.
 * @returns {Promise} - A promise that resolves with the API response data.
 * @throws {Error} - Throws an error if the request fails.
 */
export const saveCompany = async (company) => {
    const apiResponse = await post('api/company', {}, company).then(res => {
        return res;
    }).catch(error => {
        throw error;
    });

    return apiResponse;
}

/**
 * Deletes a company by sending a DELETE request to the specified API endpoint.
 * @param {number} companyId - The ID of the company to be deleted.
 * @returns {Promise} - A promise that resolves with the API response data.
 * @throws {Error} - Throws an error if the request fails.
 */
export const deleteCompany = async (companyId) => {
    const apiResponse = await del('api/company/' + companyId).then(res => {
        return res;
    }).catch(error => {
        throw error;
    });

    return apiResponse;
}